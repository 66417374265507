import type {
  Customer,
  CustomerMutation as EditableCustomerFields,
} from '@groveco/http-services'

import { createSharedComposable } from '~/composables/utils/createSharedComposable'
import { refProxy } from '~/composables/utils/refProxy'

import type { CompositionFrom } from '~/types/composable'

type ErrorNames =
  | 'LOGIN_FAILED'
  | 'UPDATE_FAILED'
  | 'FETCH_FAILED'
  | 'UNEXPECTED_ERROR'

export class CustomerError extends Error {
  name: ErrorNames
  context?: any
  constructor({
    name,
    message,
    cause,
    context,
  }: {
    name: ErrorNames
    message: string
    cause?: Error
    context?: any
  }) {
    super()
    this.name = name
    this.message = message
    this.cause = cause
    this.context = context
  }
}

export interface UseCustomer extends CompositionFrom<Customer> {
  updateCustomer: (partial: EditableCustomerFields) => Promise<void>
  login: (email: string, password: string) => Promise<void>
}

const customer = () => {
  const customerRefs = refProxy({}) as UseCustomer

  /**
   * Updates a customer
   */
  customerRefs.updateCustomer = async () => {
    return await undefined
  }

  /**
   * Logs in a customer
   */
  customerRefs.login = async () => {
    return await undefined
  }

  customerRefs.id.value = ''
  customerRefs.isLoggedIn.value = false

  return customerRefs
}

export default createSharedComposable(customer) as () => UseCustomer
